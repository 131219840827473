/***********************************************************************/
/*** General ***********************************************************/
/***********************************************************************/

body {
  background-color: #000000;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: -webkit-fit-available;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100vh;
  height: 100dvh;
}

.pointer {
  cursor: pointer !important;
  user-select: none;
}

.grab {
  cursor: grab !important;
  user-select: none;
}

.center-column {
  display: flex;
}

.center-column div {
  display: flex;
  flex: 1;
  justify-content: center;
}

.center-column div:first-child > span {
  margin-right: auto;
}

.center-column div:last-child > span {
  margin-left: auto;
}

.dropdown-item:active {
  background-color: #dadcdf;
  color: inherit;
}

.spinner {
  background-image: linear-gradient(90deg, #eeeeee, #cccccc, #eeeeee);
  background-size: 200% 100%;
  animation: background-slide 2s infinite ease;
  text-transform: lowercase;
  font-variant: small-caps;
  text-align: center;
  font-size: small;
  font-weight: 600;
}

.highlight {
  background-color: #ffefb3;
  color: black;
}

.bouncer {
  position: relative;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

@keyframes bounce-right {
  0% {
    left: -4px;
  }
  100% {
    left: 1px;
  }
}

@keyframes bounce-left {
  0% {
    right: -4px;
  }
  100% {
    right: 1px;
  }
}

@keyframes bounce-up {
  0% {
    bottom: -4px;
  }
  100% {
    bottom: 1px;
  }
}

@keyframes bounce-down {
  0% {
    top: -4px;
  }
  100% {
    top: 1px;
  }
}

.flasher {
  opacity: 1;
  animation: flash 0.5s infinite cubic-bezier(1, 0, 0, 1);
}

.slow-flasher {
  opacity: 1;
  animation: flash 1s infinite cubic-bezier(1, 0, 0, 1);
}

@keyframes flash {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.flash {
  animation: flash-once 1s cubic-bezier(1, 0, 1, 1);
}

.slow-flash {
  animation: flash-once 3s cubic-bezier(1, 0, 1, 1);
}

@keyframes flash-once {
  0% {
    background: #fec;
  }
}

.flip-box {
  position: relative;
  perspective: 1000px;
}

.flip-box-inner {
  width: 100%;
  height: fit-content;
  position: relative;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.flip-box.reversed .flip-box-inner {
  transform: rotateY(180deg);
}

.flip-box-front,
.flip-box-back {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  height: fit-content;
}

.flip-box-back {
  height: 100%;
  position: absolute;
  top: 0;
  flex-grow: 1;
  transform: rotateY(180deg);
  overflow-y: hidden;
  overflow-x: auto;
}

.Typist .Cursor--blinking {
  opacity: 1;
  animation: blink 1s linear infinite;
}

.parallax {
  perspective: 1000px;
}

.tooltip {
  font-size: small;
}

.tooltip-inner {
  background: #eee;
  color: #222;
}

.circle-icon {
  justify-content: center;
  align-items: center;
  padding-bottom: 0.2em;
}

.circle-icon-content {
  justify-content: center;
  align-items: center;
  font-size: 1.3em;
  margin-bottom: -0.2em;
}

.circle-icon-label {
  text-align: center;
}

.hover-highlight:hover {
  background-color: #fafafa;
}

.underline {
  border-bottom: 2px dashed black;
}

/*** Settings **********************************************************/

.settings .form-check {
  margin-bottom: 0.5em;
}

/*** Sidebar ***********************************************************/

.strategy .sidebar,
.dashboard .sidebar {
  position: relative;
  transition: margin-left 0.3s, width 0.3s;
}

.sidebar .sidebar-content {
  animation: opacity-appear 0.3s;
}

.sidebar section {
  transition: opacity 0.5s ease-in-out;
}

@media (max-width: 992px) {
  .strategy .sidebar,
  .dashboard .sidebar {
    position: absolute;
    box-shadow: 0 5px 5px black;
    z-index: 700;
    height: calc(100dvh - 60px);
  }
}

/*** Logo ***************************************************************/

.logo {
  display: flex;
  align-items: center !important;
  color: white;
}

.logo img {
  height: 55%;
  cursor: pointer;
}

.logo .title {
  font-weight: 500;
  font-size: 28px;
  font-family: "Oswald";
  margin-right: auto;
  cursor: pointer;
  font-feature-settings: "kern";
  letter-spacing: -1px;
}

/*** Data Items ********************************************************/

.description code, .pill {
  padding-left: 0.5em;
  padding-right: 0.5em;
  padding-top: 0.2em;
  padding-bottom: 0.2em;
  border-radius: 0.25em;
  font-weight: 600;
  color: white;
  cursor: default;
  margin-right: 5px;
  font-size: 14px;
  display: inline-block;
  margin-top: 2px;
  margin-bottom: 2px;
  white-space: nowrap;
}

.description code,
.global-variable,
.pill.small {
  user-select: none;
  padding-top: 0.1em;
  padding-right: 0.5em;
  padding-bottom: 0.1em;
  padding-left: 0.5em;
  font-weight: 600;
  font-size: 12px;
}

.strategy .entity .global-variable {
  bottom: 0;
  cursor: pointer;
  font-size: 0.8em;
}

.entity .pill.huge {
  font-size: 16px;
}

.pill.small {
  border: none !important;
}

.global-variable-value {
  background-color: #fffa;
  color: black;
  margin-top: -0.1em;
  padding-top: 0.1em;
  margin-right: -0.5em;
  padding-right: 0.5em;
  margin-left: 0.5em;
  padding-left: 0.3em;
  margin-bottom: -0.1em;
  padding-bottom: 0.1em;
}

.description code,
.entity-output-type-none,
.entity-input-type-none {
  background: #aaa;
}

.entity-output-type-timestamp,
.entity-input-type-timestamp {
  background: hsl(30, 60%, 50%);
}

.entity-output-type-timestamp.global-variable,
.entity-input-type-timestamp.global-variable {
  background: linear-gradient(
    315deg,
    hsl(30, 60%, 50%),
    hsla(30, 60%, 50%, 80%),
    hsl(30, 60%, 50%),
    hsla(30, 60%, 50%, 80%),
    hsl(30, 60%, 50%),
    hsla(30, 60%, 50%, 80%),
    hsl(30, 60%, 50%),
    hsla(30, 60%, 50%, 80%),
    hsl(30, 60%, 50%),
    hsla(30, 60%, 50%, 80%),
    hsl(30, 60%, 50%),
    hsla(30, 60%, 50%, 80%),
    hsl(30, 60%, 50%),
    hsla(30, 60%, 50%, 80%),
    hsl(30, 60%, 50%),
    hsla(30, 60%, 50%, 80%),
    hsl(30, 60%, 50%),
    hsla(30, 60%, 50%, 80%),
    hsl(30, 60%, 50%)
  );
}

.entity-output-type-number,
.entity-input-type-number {
  background: hsl(0, 60%, 50%);
}

.entity-output-type-number.global-variable,
.entity-input-type-number.global-variable {
  background: linear-gradient(
    315deg,
    hsl(0, 60%, 50%),
    hsla(0, 60%, 50%, 80%),
    hsl(0, 60%, 50%),
    hsla(0, 60%, 50%, 80%),
    hsl(0, 60%, 50%),
    hsla(0, 60%, 50%, 80%),
    hsl(0, 60%, 50%),
    hsla(0, 60%, 50%, 80%),
    hsl(0, 60%, 50%),
    hsla(0, 60%, 50%, 80%),
    hsl(0, 60%, 50%),
    hsla(0, 60%, 50%, 80%),
    hsl(0, 60%, 50%),
    hsla(0, 60%, 50%, 80%),
    hsl(0, 60%, 50%),
    hsla(0, 60%, 50%, 80%),
    hsl(0, 60%, 50%),
    hsla(0, 60%, 50%, 80%),
    hsl(0, 60%, 50%)
  );
}

.entity-output-type-text,
.entity-input-type-text {
  background: hsl(200, 60%, 50%);
}

.entity-output-type-text.global-variable,
.entity-input-type-text.global-variable {
  background: linear-gradient(
    315deg,
    hsl(200, 60%, 50%),
    hsla(200, 60%, 50%, 80%),
    hsl(200, 60%, 50%),
    hsla(200, 60%, 50%, 80%),
    hsl(200, 60%, 50%),
    hsla(200, 60%, 50%, 80%),
    hsl(200, 60%, 50%),
    hsla(200, 60%, 50%, 80%),
    hsl(200, 60%, 50%),
    hsla(200, 60%, 50%, 80%),
    hsl(200, 60%, 50%),
    hsla(200, 60%, 50%, 80%),
    hsl(200, 60%, 50%),
    hsla(200, 60%, 50%, 80%),
    hsl(200, 60%, 50%),
    hsla(200, 60%, 50%, 80%),
    hsl(200, 60%, 50%),
    hsla(200, 60%, 50%, 80%),
    hsl(200, 60%, 50%)
  );
}

.entity-output-type-bool,
.entity-input-type-bool {
  background: hsl(120, 60%, 40%);
}

.entity-output-type-bool.global-variable,
.entity-input-type-bool.global-variable {
  background: linear-gradient(
    315deg,
    hsl(120, 60%, 50%),
    hsla(120, 60%, 50%, 80%),
    hsl(120, 60%, 50%),
    hsla(120, 60%, 50%, 80%),
    hsl(120, 60%, 50%),
    hsla(120, 60%, 50%, 80%),
    hsl(120, 60%, 50%),
    hsla(120, 60%, 50%, 80%),
    hsl(120, 60%, 50%),
    hsla(120, 60%, 50%, 80%),
    hsl(120, 60%, 50%),
    hsla(120, 60%, 50%, 80%),
    hsl(120, 60%, 50%),
    hsla(120, 60%, 50%, 80%),
    hsl(120, 60%, 50%),
    hsla(120, 60%, 50%, 80%),
    hsl(120, 60%, 50%),
    hsla(120, 60%, 50%, 80%),
    hsl(120, 60%, 50%)
  );
}

.entity-output-type-account,
.entity-input-type-account {
  background: hsl(280, 60%, 40%);
}

.entity-output-type-account.global-variable,
.entity-input-type-account.global-variable {
  background: linear-gradient(
    315deg,
    hsl(280, 55%, 50%),
    hsla(280, 55%, 50%, 80%),
    hsl(280, 55%, 50%),
    hsla(280, 55%, 50%, 80%),
    hsl(280, 55%, 50%),
    hsla(280, 55%, 50%, 80%),
    hsl(280, 55%, 50%),
    hsla(280, 55%, 50%, 80%),
    hsl(280, 55%, 50%),
    hsla(280, 55%, 50%, 80%),
    hsl(280, 55%, 50%),
    hsla(280, 55%, 50%, 80%),
    hsl(280, 55%, 50%),
    hsla(280, 55%, 50%, 80%),
    hsl(280, 55%, 50%),
    hsla(280, 55%, 50%, 80%),
    hsl(280, 55%, 50%),
    hsla(280, 55%, 50%, 80%),
    hsl(280, 55%, 50%)
  );
}

.entity-output-type-any,
.entity-input-type-any {
  background: linear-gradient(
    90deg,
    hsl(200, 50%, 50%),
    hsl(120, 50%, 40%),
    hsl(30, 50%, 50%),
    hsl(0, 50%, 50%)
  );
}

.entity-output-handle,
.entity-input-handle {
  color: #888;
  position: absolute;
  top: calc(50% - 15px);
  right: -15px;
  width: 30px;
  height: 30px;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 15px;
  background: white;
  box-shadow: inset 0 0 3px #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
}

.entity-input-item {
  text-align: right;
}

.entity-input-item .pill {
  margin-right: 0;
  margin-left: 5px;
}

/*** Dataset Chooser ***************************************************/

.dataset-item {
  padding: 0.25em;
}

.dataset-chooser {
  font-size: 0.9em;
}

.dataset-chooser button::after {
  display: none;
}

.dataset-chooser .dropdown-menu .dropdown-item {
  cursor: pointer;
}

.dataset-chooser .dataset-name {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dataset-name {
  font-weight: 600;
  font-size: 1em;
  text-align: left;
}

/*** Dataset Visualization *********************************************/

.density-plot-wrapper {
  width: 100%;
  position: relative;
}

.dataset-density-message {
  text-align: center;
  font-weight: bold;
  font-size: small;
}

.dataset-density-message div {
  background-color: #cfc;
  opacity: 0.5;
  padding-left: 0.5em;
  padding-right: 0.5em;
  font-variant: small-caps;
  text-transform: lowercase;
  cursor: default;
  position: absolute;
}

@keyframes background-slide {
  0% {
    background-position-x: 0%;
  }
  50% {
    background-position-x: 100%;
  }
  100% {
    background-position-x: 200%;
  }
}

@keyframes slide {
  0% {
    left: -400px;
  }
  100% {
    left: 0;
  }
}

/*** User Item *********************************************************/

.user-item-small .user-item-photo {
  position: relative;
  width: 42px;
  aspect-ratio: 1;
}

.user-item .user-item-photo .profile {
  border-radius: 50px;
  aspect-ratio: 1;
  width: 100%;
  border: 2px solid black;
}

/*** Strategy/Dataset Card *********************************************/

.strategy-container,
.dataset-container {
  padding: 0;
  z-index: 0;
}

.modal .dataset-container {
  min-height: 800px;
}

.dashboard .strategy-card-container .card,
.dashboard .dataset-card-container .card {
  display: flex;
  width: 400px;
  margin: 0;
}

.dataset {
  width: 400px;
  margin-right: 0.8em;
  margin-bottom: 0.8em;
}

.dataset .sample-rows-container {
  height: 0;
}

.sample-row-container {
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25em;
  padding: 0.5em;
}

.alert {
  background: rgb(255, 221, 194);
  padding: 0.25em 0.5em 0.25em 0.5em;
  font-size: small;
  color: #222;
  cursor: pointer;
  border-radius: 0;
  font-weight: 600;
  font-variant: small-caps;
  text-transform: lowercase;
}

.strategy-card-container .card .strategy-diagram {
  border-bottom: 1px solid #ddd;
}

.strategy-card-container .strategy {
  height: 200px;
  width: 400px;
  position: relative;
}

.strategy-card-container .entity-holders {
  zoom: 0.28;
  pointer-events: none;
}

.strategy-card-container .owner-name,
.dataset-card-container .owner-name {
  font-size: small;
  font-weight: normal;
  color: gray;
}

/*** Entity Picker *****************************************************/

.entity-picker .sidebar-heading div:last-child {
  margin-left: auto;
}

.entity-picker .list-group .list-group-item.loading {
  background-image: linear-gradient(90deg, #eeeeee, #cccccc, #eeeeee);
  background-size: 200% 100%;
  animation: background-slide 2s infinite ease;
}

.entity-picker .entity .favorite {
  position: absolute;
  top: 10px;
  right: 10px;
  color: transparent;
  cursor: pointer;
}

.entity-picker .entity .favorite svg {
  position: absolute;
  top: 0;
  right: 0;
}

.entity-picker .entity .favorite .filled.inactive {
  display: none;
}

.entity-picker .entity:hover .favorite .active {
  color: #222;
}

.entity-picker .entity:hover .favorite .inactive {
  color: #ccc;
}

.entity-picker .entity:hover .favorite:hover .active.filled {
  display: none;
}

.entity-picker .entity:hover .favorite .inactive:hover {
  color: #222;
}

.entity-picker .entity:hover .favorite .active:hover {
  color: #ccc;
}

/*** Help **************************************************************/

.help-menu .btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.help-menu code {
  color: #eee;
}

/***********************************************************************/
/*** Layouts ***********************************************************/
/***********************************************************************/

.column-layout-container {
  white-space: nowrap;
  /* transition: margin-left 0.3s ease-in-out; */
}

@media (min-width: 992px) {
  .column-layout-container {
    margin-left: 0 !important;
  }
}

.column-layout-column {
  padding: 0.5em 1em;
  display: inline-block;
  white-space: normal;
}

@media (max-width: 992px) {
  .column-layout-column {
    width: 100vw !important;
  }
}

/***********************************************************************/
/*** Landing Page ******************************************************/
/***********************************************************************/

.landing-page {
  color: white;
  width: 100%;
  background: black;
  overflow: auto;
}

.landing-page .logo {
  position: relative;
  height: 40px;
  margin-left: 20px;
}

.landing-page .hero {
  text-align: center;
  font-weight: bold;
  width: 800px;
}

.landing-page .hero-holder {
  padding-bottom: 20px;
}

.landing-page .xl-header {
  font-family: "Montserrat";
  font-size: 66px;
  line-height: 1.1em;
}

.landing-page .md-header {
  font-family: "Montserrat";
  font-size: 48px;
  line-height: 1.1em;
}

.landing-page .sm-header {
  font-family: "Montserrat";
  font-size: 24px;
  line-height: 1.1em;
}

.landing-page .xl-header .description {
  font-weight: 500;
  font-size: 32px;
  margin-top: 1em;
}

.landing-page .md-header .description {
  font-weight: 500;
  font-size: 28px;
  margin-top: 1em;
}

.landing-page .sm-header .description {
  font-weight: 500;
  font-size: 18px;
  margin-top: 1em;
}

.landing-page .sm-signup .email input {
  border-radius: 10px 10px 0 0;
}

.landing-page .sm-signup .email .btn {
  border-radius: 0 0 10px 10px;
}

.landing-page .email input {
  background: transparent;
  color: white;
  font-weight: bold;
  font-size: 20px;
  padding: 0.5em;
  background: #222;
  text-align: center;
}

.landing-page .email input:focus {
  border: 1px solid #ccc;
  outline: 0;
  box-shadow: none;
}

.landing-page .email .btn {
  font-weight: bold;
}

.landing-page .auth {
  min-height: 200px;
}

.landing-page .teaser-holder {
  overflow: hidden;
  text-align: center;
  background: black;
}

.landing-page video {
  width: 100%;
  border: 3px solid #ffc00755;
  border-radius: 15px;
}

.landing-page .teaser-front video {
  width: 300%;
  border: 0;
  border-radius: 0;
}

.landing-page .teaser-perspective {
  perspective: 1000px;
  margin-bottom: 100px;
}

.landing-page .teaser-front {
  width: 100%;
}

.landing-page .screenshot::before {
  position: absolute;
  z-index: -1;
  transform: scale(1.5);
  width: 100%;
  height: 100%;
  background: radial-gradient(50% 50% at 50% 50%, #b547f721 0%, #25262c00 100%);
}

.landing-page .shine {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: hsla(0, 0, 100%, 0.03);
}

.landing-page .idea .content {
  padding-left: 25px;
  padding-top: 20px;
  text-align: left;
  font-size: 29px;
  line-height: 36px;
  font-weight: 600;
}

/***********************************************************************/
/*** Header ************************************************************/
/***********************************************************************/

.navbar {
  padding-left: 1em;
  padding-right: 1em;
  user-select: none;
  height: 60px;
}

.navbar .header-item {
  color: white;
  height: 100%;
  display: flex;
  justify-content: center !important;
}

.navbar .header-menu {
  justify-content: end !important;
  flex-grow: 0;
}

.navbar .header-item button {
  font-variant: small-caps;
  text-transform: lowercase;
  font-family: "Oswald";
  padding-top: 0;
}

.navbar .header-item .user {
  height: 80%;
  margin-left: 1em;
  cursor: pointer;
}

.navbar .header-item img.user {
  height: 80%;
  border-radius: 25px;
}

/***********************************************************************/
/*** Dashboard *********************************************************/
/***********************************************************************/

.dashboard .user-card .user-card-photo {
  border-radius: 50px;
  width: 50px;
  height: 50px;
}

@media (min-width: 992px) {
  .dashboard .strategy-header .strategy-name,
  .dashboard .header {
    width: 100%;
    display: flex;
    font-weight: 600;
    font-size: 1.3em;
  }
}

.dashboard .strategy-card {
  font-size: 15px;
  margin: 0.5em;
  padding: 1em;
  border: 1px solid rgb(238, 238, 238);
}

.dashboard .strategy-card .description {
  height: 70px !important;
  flex-grow: 1;
  overflow: hidden;
}

.dashboard .strategy-card .description::after {
  content: " ";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50px;
  background: linear-gradient(0deg, #ffff, #fff0);
}

.dashboard .strategy-card:hover .description::after {
  background: linear-gradient(0deg, #fafafa, #fefefe00);
}

@media (max-width: 992px) {
  .dashboard .strategy-card {
    font-size: 14px;
  }

  .dashboard .strategy-details {
    width: 100%;
  }

  .dashboard .strategy-details {
    font-size: 0.875em;
  }

  .dashboard .strategy-details-container {
    width: 100% !important;
  }

  .dashboard .strategy-header .strategy-name,
  .dashboard .header {
    width: 100%;
    display: flex;
    font-weight: 600;
    font-size: 1.2em;
  }

  .dashboard .strategy-header textarea {
    display: none;
  }
}

.dashboard .strategy-details-container .summary {
  margin-bottom: 1em;
}

.dashboard .strategy-header .strategy-name input {
  position: relative;
  margin-top: -14px;
  margin-left: -14px;
  top: 7px;
  left: 1px;
  flex-grow: 1;
  font-weight: 600;
  font-size: 21px;
}

.dashboard .strategy-header textarea.description {
  position: relative;
  margin-top: -14px;
  margin-left: -14px;
  top: 7px;
  left: 1px;
  flex-grow: 1;
}

.dashboard .strategy-header input.description {
  position: relative;
  margin-top: -2px;
  margin-left: -14px;
  top: 1px;
  left: 1px;
  flex-grow: 1;
  padding-top: 0;
  padding-bottom: 0;
}

.dashboard .team-details-content .strategy-header p {
  margin-bottom: 0;
  font-size: 1.1em;
  margin-top: 0.25em;
}

.dashboard .strategy-header.collapsed .description p {
  display: none;
}

.dashboard .strategy-header.collapsed hr {
  display: none;
}

.dashboard .strategy-header.collapsed .description p:first-of-type {
  display: block;
  margin-bottom: 0;
}

.dashboard .strategy-header p {
  margin-bottom: 1.5rem;
}

.dashboard .separator:last-child {
  display: none !important;
}

.dashboard .strategy-header p:last-child {
  margin-bottom: 0;
}

.dashboard .team-details-content textarea {
  display: none;
}

.dashboard .strategy-header .user {
  width: 50px;
  height: 50px;
  border-radius: 50px;
}

.dashboard .strategy-header .strategy-diagram,
.dashboard .team-details-content .strategy-diagram,
.dashboard .block-diagram {
  cursor: pointer;
  margin-left: -1rem;
  margin-right: -1rem;
  margin-bottom: -1rem;
  height: fit-content;
  background: radial-gradient(#00000055 10%, transparent 10%) #fffbf0;
  background-size: 5px 5px;
}

.dashboard .strategy-header .strategy-diagram .entity-holders,
.dashboard .team-details-content .strategy-diagram .entity-holders,
.dashboard .block-diagram .entity-holders {
  padding-top: 20px;
  padding-bottom: 20px;
  height: fit-content;
}

.dashboard .strategy-header .strategy-diagram .entity-holders .entity-wrapper,
.dashboard .team-details-content .strategy-diagram .entity-holders .entity-wrapper,
.dashboard .block-diagram .entity-holders .entity-wrapper {
  position: relative !important;
  left: calc(50% - 162px) !important;
}

.dashboard .strategy-header .block-label {
  margin-bottom: 0;
  font-weight: 600;
  margin-left: 0.25em;
  font-size: 80%;
}

.dashboard .strategy-header .block-field {
  margin-bottom: 0.5em;
}

.dashboard .strategy-header .block-input-name {
  margin-bottom: 0.5em;
}

.dashboard .strategy-diagram-container {
  z-index: 0;
  min-width: 200px;
  height: calc(100vh - 60px);
}

.dashboard .strategy-diagram-container .entity-holders {
  pointer-events: none;
}

.dashboard .card .strategy-diagram {
  cursor: pointer;
}

.dashboard .card .strategy-diagram .entity-holders::after {
  display: none;
}

.dashboard .strategy-header .strategy-diagram .entity-holders,
.dashboard .team-details-content .strategy-diagram .entity-holders,
.dashboard .block-diagram .entity-holders {
  position: relative;
  pointer-events: none;
  width: 100%;
}

.dashboard .card .nav-link {
  color: #888;
  border-bottom: 0;
}

.dashboard .card .nav-link.active {
  color: #222;
}

.dashboard .card .card-header-tabs {
  margin: 0;
  isolation: isolate;
}

.dashboard .tab-content {
  overflow: auto;
  height: 0;
  flex-grow: 1;
}

.dashboard .card .strategy-tab-content {
  padding: 1em;
  border: 1px solid #dfd2e6;
}

.dashboard .card {
  background: linear-gradient(180deg, #f5f5f5, white 50px) white;
}

.dashboard .strategy-details-container {
  transition: margin-left 0.5s;
  flex-shrink: 0;
  padding-top: 1em;
  padding-right: 0.5em;
  padding-left: 1em;
  padding-bottom: 1em;
}

.dashboard .strategy-details-container::-webkit-scrollbar {
  display: none;
}

.dashboard .strategy.strategy-diagram .strategy-editor-shadow {
  display: none;
}

.dashboard .strategy .entity-holders::after {
  opacity: 0.5;
}

.dashboard .strategy-card-container .card {
  margin-right: 0;
  margin-bottom: 0;
}

.dashboard .strategy-diagram-container {
  position: relative;
  overflow: hidden;
  margin-left: 1em;
}

.team-list-item {
  padding-left: 0.5em;
  font-size: 0.9em;
}

.team-list-item .team-logo-image {
  width: 40px;
  height: 40px;
}

.team-list-item.small .team-strategies {
  display: none;
}

.team-list-item.small {
  display: inline-block;
  width: auto;
  border: none;
  padding: 0.25em;
  background: transparent;
}

.team-list-item.small .team-logo-image {
  width: 14px;
  height: 14px;
}

.team-list-item.small .team-name {
  font-size: 12px;
}

.dashboard .team-details .team-name {
  font-weight: 600;
}

.dashboard .team-details .team-description {
  font-size: 0.9em;
}

.dashboard .team-details .team-photo {
  height: 60px;
}

.dashboard .heading {
  font-weight: 600;
}

.dashboard .user-summary {
  width: 400px;
}

.dashboard .user-summary .logout {
  position: absolute;
  top: 10px;
  right: 10px;
}

.data-explorer {
  z-index: 0;
}

.data-explorer .sensor-selector {
  scroll-snap-type: y mandatory;
  height: 0;
}

.sensor-selector::-webkit-scrollbar {
  display: none;
}

.sensor-selector .card {
  display: inline-block;
  scroll-snap-align: start;
  margin: 0;
  margin-bottom: 1em;
  white-space: normal;
}

.upload-dialog .sensor-selector .card {
  margin: 0;
}

.upload-dialog .selected-sensor .dropdown-toggle:not(.simple)::after {
  content: none;
}

.upload-dialog .sensor-selector li {
  list-style: none;
}

.upload-dialog input[type="time"] {
  border: 0;
}

.sensor-selector .entity.muted {
  opacity: 0.5;
}

.dataset-density-holder {
  height: 55px;
  margin-bottom: 20px;
  position: relative;
  background-color: #888;
}

.legend {
  z-index: 1;
  padding-right: 5px;
  background: #cfc8;
  position: absolute;
  right: 0;
}

.legend .swatch {
  width: 8px;
  height: 8px;
  border: 1px solid #555;
  margin-left: 5px;
}

.label {
  color: #555;
  font-variant: small-caps;
  text-transform: lowercase;
}

.legend .label {
  font-size: 10px;
  margin-left: 5px;
}

.upload-dialog table.dataframe {
  width: 100%;
  font-size: small;
  text-align: right;
}

.upload-dialog .loading table {
  opacity: 0.5;
}

.upload-dialog emph {
  font-style: italic;
}

.upload-dialog .loading tbody,
.upload-dialog-footer .columns-preview.loading .pill {
  background-image: linear-gradient(90deg, #eeeeee, #cccccc, #eeeeee);
  background-size: 200% 100%;
  animation: background-slide 2s infinite ease;
}

.upload-dialog .preview,
.upload-dialog .table-container {
  height: 400px;
  position: relative;
}

.upload-dialog .final-preview {
  height: 300px;
  overflow-y: hidden;
  display: flex;
  justify-content: flex-start;
}

.upload-dialog-footer {
  flex-wrap: nowrap;
}

.upload-dialog-footer .columns-preview {
  min-height: 30px;
}

.upload-dialog .preview::after,
.upload-dialog .final-preview::after,
.upload-dialog .table-container::after {
  content: " ";
  position: absolute;
  height: 120px;
  right: 0;
  left: 0;
  bottom: 0;
  background: linear-gradient(0deg, white 30%, #fff0);
}

.upload-dialog .preview table:not(.fake) tbody tr:hover {
  background-color: #ffc !important;
  cursor: pointer;
}

.upload-dialog .preview table:not(.fake) thead tr {
  background-color: #ffc !important;
}

.upload-dialog table.dataframe tbody tr:nth-child(odd) {
  background: #eee;
}

.upload-dialog .required {
  font-variant: small-caps;
  font-size: x-small;
  background: white;
  padding-left: 0.25em;
  padding-right: 0.25em;
}

.dashboard .menu-item.list-group-item {
  background-color: transparent;
  color: #ccc;
  font-weight: 400;
  border: 0;
  font-family: "Roboto";
}

.dashboard .strategy-item.menu-item,
.dashboard .block-item.menu-item {
  font-size: smaller;
  overflow-x: hidden;
  text-overflow: ellipsis;
  margin-left: 1.46em;
  padding-top: 0.25em;
  padding-bottom: 0.25em;
}

.dashboard .menu-item .team-name {
  font-size: inherit;
}

.dashboard .menu-item:hover {
  color: white;
  font-weight: 800;
  background: transparent;
}

.dashboard .menu-item.highlighted {
  color: white;
  font-weight: 800;
}

.dashboard-backtest-item .d-table-row:hover,
.dashboard .more-charts > div div:hover {
  background: #fafafa;
}

/***********************************************************************/
/*** Strategy Viewer ***************************************************/
/***********************************************************************/

.backtest-output-control .selected-file {
  color: #fec;
  font-family: "Oswald";
  font-size: small;
}

.backtest-output-control {
  color: white;
  border: 3px solid #fec;
  border-radius: 30px;
}

.debug-controls {
  color: white;
  width: 100%;
  height: 40px;
}

.debug-controls .timestamp {
  font-family: "Oswald";
  font-size: small;
}

/***********************************************************************/
/*** Strategy Editor ***************************************************/
/***********************************************************************/

.strategy {
  height: 0;
  --z-drawing-edge: 99999;
}

.strategy .sidebar-heading,
.dashboard .sidebar-heading {
  font-variant: small-caps;
  text-transform: lowercase;
}

.sidebar-subheading {
  font-size: small;
  color: #ccc;
}

.strategy .sidebar-button,
.dashboard .sidebar-button {
  color: #ddd;
  cursor: pointer;
  font-size: small;
}

.strategy .sidebar-button:hover,
.dashboard .sidebar-button:hover {
  color: white;
}

.strategy .sidebar .entity-picker {
  overflow: auto;
}

.strategy .sidebar {
  user-select: none;
}

.strategy .sidebar .entity-picker-list {
  scroll-snap-type: y mandatory;
}

.strategy .sidebar .entity-picker .entity {
  cursor: grab;
  width: 100%;
  background: #eee;
  box-shadow: none;
  animation: none;
  scroll-snap-align: start;
}

.strategy .sidebar .entity-picker .tag {
  text-decoration: none;
  font-size: small;
  margin-right: 0.2em;
  cursor: pointer;
  font-variant: small-caps;
  text-transform: lowercase;
  font-weight: 600;
}

.strategy .sidebar .entity-picker .description {
  color: #888;
  text-decoration: none;
  font-size: 1em;
  margin-right: 0.2em;
  line-height: 100%;
  margin-top: 0.25em;
  margin-bottom: 0.25em;
}

.strategy .sidebar .entity-picker .entity-picker-list .list-group::-webkit-scrollbar {
  display: none;
}

.strategy .log-list::-webkit-scrollbar {
  display: none;
}

.log-list .global-variables-assigned {
  zoom: 0.8;
}

.strategy .strategy-state,
.strategy-backtest-state {
  font-style: italic;
}

.strategy .strategy-editor::-webkit-scrollbar {
  background-color: #222529;
}

.strategy .strategy-editor::-webkit-scrollbar-thumb {
  background-color: #fffbf0;
  border-radius: 4px;
  box-shadow: inset 0px 0px 3px 3px black;
}

.strategy .strategy-editor::-webkit-scrollbar-corner {
  background-color: #222529;
}

.strategy .strategy-editor {
  background: #222529;
}

.panel-shadow {
  box-shadow: inset 3px 3px 5px 1px #000;
  position: sticky;
  left: 0;
  width: 100%;
  top: 0;
  height: 100%;
  z-index: 603;
  pointer-events: none;
}

.dark .strategy .entity-holders {
  background: radial-gradient(#ffffff5c 10%, transparent 10%) #3e4550;
}

.strategy .entity-holders {
  background: radial-gradient(#00000055 10%, transparent 10%) #fffbf0;
  background-size: 10px 10px;
  position: absolute;
  top: 0;
  width: 10000px;
  height: 10000px;
  padding: 0;
  margin: 0;
  user-select: none;
}

.strategy .entity-holders {
  cursor: grab;
}

.strategy .selection-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 10000px;
  height: 10000px;
  z-index: 602;
  pointer-events: none;
}

.strategy .selection-container.selecting {
  pointer-events: auto;
  cursor: crosshair;
}

.strategy .entity-holders > svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 0;
}

.strategy .entity-holders > svg.temporary {
  z-index: var(--z-drawing-edge);
  stroke-width: 3px;
  stroke: black;
  fill: transparent;
}

.entity-connection path {
  fill: transparent;
}

.entity-connection.focused path {
  stroke-width: 8px;
  fill: transparent;
  stroke: black;
}

.strategy .entity-holders svg.focused-connection {
  z-index: 601;
}

path.highlight {
  stroke-dasharray: 8px 8px;
  stroke-dashoffset: 16px;
  fill: transparent;
  stroke-width: 4px;
  stroke: #f8ecdb55;
}

.draw-attention {
  pointer-events: none;
  animation: disappear 2s ease-in, strobe 0.2s linear alternate-reverse infinite;
}

.draw-attention circle {
  stroke-dasharray: 8px 8px;
  stroke-dashoffset: 16px;
  fill: transparent;
  stroke-width: 4px;
  animation: stroke 0.4s linear infinite;
}

.draw-attention circle:first-child {
  animation-delay: 0.2s;
}

path.active {
  animation: stroke 0.4s linear infinite;
  stroke: #f8ecdb;
}

@keyframes stroke {
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes disappear {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes strobe {
  from {
    scale: 0.8;
  }
  to {
    scale: 1;
  }
}

.strategy .entity-holders > svg.circle {
  z-index: var(--z-drawing-edge);
}

.strategy .entity-holders .rv-xy-plot {
  pointer-events: none;
}

.strategy .controls {
  position: sticky;
  height: 0;
  top: 10px;
  left: 10px;
  z-index: 1000;
  display: flex;
}

.strategy .entity {
  font-size: 14px;
}

.strategy .entity-title {
  padding: 1em;
}

.strategy .entity .card-title {
  font-size: 1.25em;
  margin-bottom: 0.25em;
}

.strategy .entity .card-subtitle {
  font-size: 1em;
  margin-bottom: 0.25em;
}

.entity {
  width: 23em;
  background: linear-gradient(0deg, #ffff 85%, #eee) white !important;
  background-repeat: no-repeat;
  background-size: 100% 50%;
  z-index: 2;
  box-shadow: 1px 1px 1px black;
}

.entity-wrapper.selected {
  border: 3px dashed #ccc;
  border-radius: 0.5em;
  margin-top: -3px;
  margin-left: -3px;
}

.entity-wrapper.selected .entity {
  box-shadow: none;
}

.entity .card-body {
  cursor: pointer;
}

.entity-wrapper {
  cursor: default;
}

.entity.compact h5 {
  font-size: 1em;
  margin: 0;
}

.entity .visualizations {
  margin-top: -0.5em;
}

.entity .entity-title {
  min-height: 2.2em;
}

.entity.compact .collapsed-inputs {
  border-right: 1px solid #dbdbdb;
}

.entity.variable-entity .collapsed-inputs {
  border-right: none;
}

.entity.variable-entity .entity-attributes {
  border-bottom: none;
}

.entity.variable-entity .entity-title {
  padding: 1em 1.5em 1em 1em;
  white-space: nowrap;
  overflow: hidden;
}

.entity.variable-entity .card-title {
  font-size: medium;
}

.entity.variable-entity .card-subtitle {
  display: none;
}

.strategy .entity-holders .entity .input-group-text {
  height: 100%;
}

.strategy .entity.react-draggable-dragging {
  box-shadow: 2px 2px 3px #888;
  top: -1px !important;
  left: -1px !important;
}

.entity.react-draggable-dragging .entity-output-handle {
  box-shadow: 2px 0px 3px #888;
}

.entity-input-item {
  width: 100%;
  height: 100%;
}

.entity-input-contents,
.entity-output-contents {
  position: relative;
  z-index: 1;
}

.entity-output-item {
  flex-grow: 1;
}

.entity .entity-output-item.has-connection .entity-output-handle {
  background: radial-gradient(black 10px, white 11px);
  background-position-x: 1px;
  transform: translate(0px, 0px);
}

.entity.react-draggable-dragging .entity-output-item.has-connection .entity-output-handle {
  transform: translate(1px, 1px);
}

.entity .entity-input-item.has-connection .entity-input-handle {
  background: radial-gradient(black 10px, white 11px);
  background-position-x: 0;
  transform: translate(0px, 0px);
}

.entity.react-draggable-dragging .entity-input-item.has-connection .entity-input-handle {
  transform: translate(1px, 1px);
}

@keyframes opacity {
  from {
    opacity: 0;
  }
}

@keyframes opacity-appear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.strategy .sidebar .list-group-item.ui-draggable {
  width: 100%;
}

.strategy .entity .entity-attributes {
  position: relative;
}

.strategy .entity .entity-attributes .dropdown-toggle {
  font-size: 1em;
}

.strategy .entity-buttons {
  position: absolute;
  top: 0;
  right: 0;
}

.strategy .entity .delete {
  margin-bottom: -4px;
}

.strategy .entity .pencil {
  margin-bottom: -4px;
}

.strategy .entity .stack {
  margin-bottom: -4px;
}

.strategy .entity .blockify-input {
  color: #55585b;
}

.entity .btn:hover {
  border: solid lightgrey 1px;
}

.strategy .entity .dropdown-item:active {
  background-color: #eaecef;
}

.connection-dialog {
  margin-bottom: 1em;
}

.connection-dialog.removed > div:first-child {
  opacity: 0.3;
}

.connection-dialog.removed > div:last-child {
  opacity: 0.15;
}

.connection-dialog .dropdown-item:active {
  background-color: #eaecef;
}

.connection-dialog hr {
  margin-top: 0.25em;
  margin-bottom: 0.25em;
}

.connection-dialog hr:first-child {
  display: none;
}

.strategy .connection-dialog .dropdown-item:active {
  background-color: #eaecef;
}

.strategy .entity-output-handle {
  box-shadow: inset 0 0 3px #ccc, 1px 0 1px black;
}

.strategy .entity-output-item .filter-icon {
  min-width: 18px;
}

.entity-input-handle {
  right: inherit;
  left: -15px;
  cursor: pointer;
}

.strategy .entity .list-group-item:not(.list-group-item-warning) {
  background: transparent;
}
